<template>
  <div style="height: 100% !important">
    <v-stepper class="transparent-bg" v-model="e1" flat style="height: 100%">
      <v-stepper-content :step="1" class="text-center transparent-bg" style="height: 100%">
        <v-card-title class="justify-center" style="color: #fcc03c !important"
          >Gérez votre cabinet {{ agency }}</v-card-title
        >
        <v-card-subtitle>Pour commencer, veuillez choisir où vous diriger. </v-card-subtitle>
        <v-row class="text-center align-center d-inline-flex row-study mt-5 mb-5">
          <v-col
            :cols="!$vuetify.breakpoint.xs ? '12' : '6'"
            md="6"
            v-for="(item, index) in items"
            v-bind:key="item.name"
          >
            <v-card
              :id="`agency-manager-${item.id}`"
              :to="item.to"
              outlined
              width="200"
              height="200"
              elevation="3"
              rounded="100"
              @mouseover="item.hovered = true"
              @mouseleave="item.hovered = false"
              class="align-center d-flex rounded-xl"
            >
              <v-card-text class="text-center pa-5 mt-5">
                <v-badge
                  v-if="item.badge"
                  icon="mdi-check"
                  color="EoleGreen"
                  class="white--text"
                  bottom
                  overlap
                  offset-x="20"
                  offset-y="15"
                >
                  <i
                    :class="['fa-solid ', item.icon, 'fa-5x', item.hovered ? 'EoleYellow--text' : 'EoleBlue--text']"
                  ></i>
                </v-badge>
                <i
                  v-else
                  :class="['fa-solid ', item.icon, 'fa-5x', item.hovered ? 'EoleYellow--text' : 'EoleBlue--text']"
                ></i>
                <p class="mt-5">{{ item.name }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css" // Load icons
import { ref } from "@vue/composition-api"

export default {
  data: () => ({
    e1: 1,
    valid: true,
    agency: null,
    hover: false,
    items: [
      {
        id: 1,
        name: "CABINET",
        icon: "fa-house-user",
        hovered: false,
        to: {
          name: "Office",
        },
      },
      {
        id: 2,
        name: "COMMISSIONS",
        icon: "fa-euro-sign",
        hovered: false,
        to: {
          name: "Commissions",
        },
      },
      {
        id: 3,
        name: "PARTENAIRES",
        icon: "fa-landmark-dome",
        hovered: false,
        badge: true,
        to: {
          name: "Partners",
        },
      },
      {
        id: 4,
        name: "PRODUITS",
        icon: "fa-binoculars",
        hovered: false,
        to: {
          name: "Products",
        },
      },
    ],
  }),
  methods: {},

  created() {
    this.agency = localStorage.getItem("agency")
  },
}
</script>
<style>
.mullerFont {
  font-family: muller;
  font-size: 1.4rem;
  font-weight: 200;
}

.row-study {
  max-width: 450px;
}

.transparent-bg {
  background-color: transparent !important;
}

.v-badge__badge .v-icon {
  color: white !important;
}
</style>
